@import 'style/helpers.scss';

.pageHeaderItem {
}

.searchInput {
    width: 100%;
    height: 32px;
    outline: none;

    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #ededed;

    padding-right: 15px;
    padding-left: 30px;

    &:focus {
        border: 2px solid $dark-mint;
    }
}

.divider {
    width: 1px;
    height: 23px;
    border: none;

    background: #e0e0e0;
}

.spacer {
    flex: 1;
}
