.container {
    width: 500px;
    height: 600px;
    margin-top: 30px;
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        width: 100%;
        flex: 1;
        font-size: 20px;
    }
    .comments {
        flex: 7;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .commentBox {
        flex: 3;
        width: 100%;
        height: 100%;
        min-height: 37px;
        margin-top: 18px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .commentBoxButton {
            width: 13%;
            height: 34px;
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 9px;
            font-family: Assistant;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: -0.02em;
            border: none;
            transition: 200ms;
        }
        .commentBoxActiveButton {
            width: 13%;
            height: 34px;
            background: #12a84b;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 9px;
            border: none;
            font-family: Assistant;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: -0.02em;
            color: #ffffff;
            transition: 200ms;
        }
        .commentBox {
            width: 70%;
            height: 34px;
            border: 1px solid #c8c8c9;
            box-sizing: border-box;
            border-radius: 9px;
            font-family: Assistant;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            resize: none;
            padding: 9px 13px 0 0;
            //color: #000000;
            transition: 200ms;
        }
        .commentBoxActive {
            width: 70%;
            height: 76px;
            border: 1px solid #c8c8c9;
            box-sizing: border-box;
            border-radius: 9px;
            font-family: Assistant;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            padding: 9px 13px 0 0;
            //color: #000000;
            resize: none;
            transition: 200ms;
            &:focus {
                outline: none;
            }
        }
    }
}
