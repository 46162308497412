@import 'style/helpers.scss';

.notifications {
    position: fixed;
    z-index: 7000;
    width: 0 auto;
    bottom: 30px;
    left: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    // pointer-events: none;
    align-items: flex-end;
}
