@import 'style/helpers.scss';

$padding-right: 12px;
$height: 42px;
$height-multiline: 157px;

.colorInput {
    display: inline-block;
    position: relative;
    height: $height;
    min-height: $height;
    max-height: $height;
    min-width: 200px;
    cursor: pointer;

    &[data-is-multiline='true'] {
        height: $height-multiline;
        min-height: $height-multiline;
        max-height: $height-multiline;
        width: 100%;
    }
}

.label {
    @include label();
    position: absolute;
    z-index: 100;
    right: $padding-right;
    top: 4px;
    text-align: left;
    font-size: 12px;
    user-select: none;
}

.input {
    @include input();
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #444d45;

    background: white;
    padding-top: 16px;
    padding-right: $padding-right;

    &[data-opened='true'] {
        border: 2px solid $dark-mint;
    }
}

.colorPreview {
    // position: absolute;
    // right: 10px;
    // top: 10px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    margin-top: 2px;
    margin-left: 10px;
}

.pickeContainer {
    position: absolute;
    right: 0;
    top: $height;
    z-index: 1000;
    padding-top: 8px;
    // padding-bottom: 20px;

    direction: ltr !important;

    * {
        direction: ltr !important;
    }

    &::before {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 8px 6px;
        border-color: transparent transparent $input-border-color transparent;
        position: absolute;
        top: 0;
        right: 16px;
    }

    &::after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 8px 6px;
        border-color: transparent transparent white transparent;
        position: absolute;
        top: 2px;
        right: 16px;
    }

    > button {
        border: none;
        outline: none;
        box-shadow: none;
        background: $dark-mint;
        color: white;
        border-radius: 4px;
        line-height: 30px;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        user-select: none;

        position: absolute;
        bottom: 5px;
        left: 10px;
        right: 10px;
        z-index: 100;
        display: block;
        width: 200px;
    }

    > div {
        padding-bottom: 40px !important;
    }
}
