@import 'style/helpers.scss';

.itemsFilter {
    direction: rtl !important;

    * {
        direction: rtl !important;
    }

    position: relative;
    width: 70px;
    height: 28px;
    border-radius: 4px;
    background-color: #f6f6f6;
    z-index: 1000;
    float: right;
}

.currentValue {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    cursor: pointer;
    user-select: none;
}

.currentValueOpened {
    composes: currentValue;
    border: 2px solid $dark-mint;
}

.menu {
    @include card5();
    border: solid 1px #eeeeee;
    position: absolute;
    top: 32px + 5px;
    width: 300px;
    background: white;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    //max-height: 250px;
    display: none;
    padding-top: 10px;
    overflow: hidden;

    &[data-is-right-side='true'] {
        left: 0;
    }

    &[data-is-right-side='false'] {
        right: 0;
    }
}

.menuLeft {
    composes: menu;
    left: unset;
    right: 0;
}

.menuOpened {
    composes: menu;
    display: block;
}

.menuOpenedLeft {
    composes: menuOpened;
    left: unset;
    right: 0;
}

.items {
    &:focus {
        outline: none;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        width: 8px;
        height: 75px;
        border-radius: 4px;
        background-color: #ebebeb;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        width: 4px;
        height: 75px;
        border-radius: 4px;
        background-color: $dark-mint;
    }
}

.itemLoading {
    width: 100%;
    height: 35px;
    padding: 10px;

    &::before {
        display: block;
        content: '';
        border-radius: 4px;
        width: 100%;
        height: 100%;
        @include animated-background-pulse(100% 100%);
    }
}

.item {
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    padding-left: 8px;
    padding-right: 5px;
    font-weight: 500;

    &:hover {
        font-weight: 700;
    }

    > div:nth-of-type(1) {
        margin-right: 5px;
    }

    &:nth-of-type(2n) {
        background: #f6f6f6;
    }
}

.loadStub {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 240px;
}

.searchContainer {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
    padding-bottom: 10px;
}

.search {
    direction: rtl;

    * {
        direction: rtl;
    }

    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: #fafafa;
    border: solid 1px #ededed;

    position: relative;

    > i {
        position: absolute;
        left: 10px;
        top: 5px;
        color: $dark-mint;
        font-size: 20px;
    }

    > input {
        @include input();
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        box-shadow: none;
        background: none;

        &::placeholder {
            font-size: 14px;
            font-weight: 500;
            font-style: oblique;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #bababa;
        }
    }
}

.filtered {
    line-height: 28px;
    font-weight: 600;
    font-size: 13px;
    white-space: nowrap;
    color: $white;
    width: 100%;
    height: 100%;
    background: $dark-mint;
    text-align: center;
    border-radius: 4px;
}

.statistic {
    width: 100%;
    text-align: right;
    border-top: 1px solid lighter($gray, 40%);
    height: 40px;
    line-height: 40px;
    padding-right: 15px;
    font-weight: 600;
}

.buttons {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    //margin-top: 20px;
    padding-right: 10px;
    padding-left: 10px;

    justify-content: flex-start;

    > div {
        margin-left: 10px;
    }
}

.cancelButton {
    @include not-active-button();
}

.applyButton {
    @include active-button();
    width: 120px;
}
