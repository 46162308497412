@import 'style/helpers.scss';

.horizontalGroup {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 0;
    position: relative;

    > *[data-role='view'] {
        margin-left: 10px;

        &:last-of-type {
            margin-left: 0;
        }
    }
}
