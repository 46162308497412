@import 'style/helpers.scss';

.tagsCell {
    position: relative;
    text-align: right;
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
}

.tag {
    font-family: Aran;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    color: #444d45;
}

.displayTags {
    display: inline-block;
}

.hash {
    color: $dark-mint;
}
