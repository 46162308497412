$black: #393939;
$green: #43bf60;
$red: #f00;
$blue: #00f;
$white: #fff;
$gray: #999;

$charcoal-grey: #2a2b30;
$medium-grey: #5d5f5d;
$tomato: #e74040;
$coral-pink: #ff506a;
$dark-mint: #43bf60;
$macaroni-and-cheese: #ecb748;
$algae-green: #27c249;
$loading-color: #e0e0e0;

$red: #f00;
$blue: #00f;
$white: #fff;
$gray: #999;
$black: #000;

$charcoal-grey: #2a2b30;
$medium-grey: #5d5f5d;
$tomato: #e74040;
$coral-pink: #ff506a;
$dark-mint: #43bf60;
$macaroni-and-cheese: #ecb748;
$algae-green: #27c249;

$loading-color: #e0e0e0;

$side-bar-width: 211px;

//$disabled-input-background: #fafafa;
$disabled-input-background: #fefefe;

$input-border-color: #d6d6d6;
