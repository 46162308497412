@import 'style/helpers.scss';

.pagingInner {
    display: inline-block;
    padding-left: 0;
    border-radius: 4px;
    padding-right: 18px;
    margin: 0px;
    user-select: none;

    * {
        user-select: none;
    }
}

@media (max-width: 524px) {
    .pagingInner {
        padding-right: 0;
        font-size: 14px;
    }
}

.pagingItem {
    display: inline;
    position: relative;
    float: right;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    margin-left: -1px;
    :visited {
        color: unset;
    }
    text-decoration: none;
    color: $gray;
    a:link {
        color: $gray;
    }
}

.pagingActive {
    cursor: default;
    text-decoration: none;
    color: $dark-mint;
    a:link {
        color: $dark-mint;
    }
    border-radius: 8px;
    background-color: white;
    font-weight: bold;
}
.pagingActiveLink {
}
.pagingItemPrev {
    color: $black;
    a:link {
        color: $black;
    }
}
.pagingItemNext {
    color: $black;
    a:link {
        color: $black;
    }
}
.pagingItemFirst {
    color: $black;
    a:link {
        color: $black;
    }
}
.pagingItemLast {
    color: $black;
    a:link {
        color: $black;
    }
}
.pagingDisabled {
    cursor: not-allowed;
    text-decoration: none;
}
