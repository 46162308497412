@import 'style/helpers.scss';

.statisticBlock {
    background-color: #ebebeb;
    min-width: 200px;
    border-radius: 10px;
}

.title {
    color: #999;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 3px;
}

.statistic {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 16px;
}
