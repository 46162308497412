@import 'style/helpers.scss';

.dialog {
    @include card3();
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;

    > div:nth-of-type(2) {
        padding: 0;
        background: #f4f4f4;
    }
}

.commandPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: white;

    > * {
        margin-left: 15px;
    }
    > *:last-child {
        margin-left: 0;
    }
}

.historyGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid #eee;
    width: 0;
    max-width: 250px;
    height: 100%;
    overflow-y: scroll;
    transition: width ease-in-out 300ms;
}

.historyGroupOpen {
    composes: historyGroup;
    width: 250px;
    min-width: 250px;
    flex: 0 auto;
}

.historyTitle {
    text-align: center;
    font-weight: bold;
    background: white;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.historyItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;

    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid #eee;
    direction: ltr;
    font-size: 14px;

    cursor: pointer;
    user-select: none;

    * {
        direction: ltr;
    }
}
