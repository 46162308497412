@import 'style/helpers.scss';

.sidebar {
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    width: 211px;
    min-width: 211px;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.03);
    border-left: solid 1px #ededed;
    z-index: 0;
    background-color: #ffffff;
    max-height: 100%;
}

.hamburgerMenu {
    display: none;
}

@media (max-width: 524px) {
    .sidebar {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 10000;
        // > div:nth-of-type(1) {
        //     display: none;
        // }

        &[data-is-opened='false'] {
            display: none;
        }
    }

    .hamburgerMenu {
        position: fixed;
        top: 20px;
        right: 15px;
        display: block;
        z-index: 11000;
    }
}

.items {
    grid-row-start: 2;
    overflow: auto;
}

.siteItem,
.serviceItem {
    display: flex;
    align-items: center;
    list-style: none;
    height: 60px;
    padding: 0 25px;
    vertical-align: middle;
    cursor: pointer;
    border-bottom: solid 1px #ededed;
    transition: background-color ease-in 300ms;
    color: #454443;
    user-select: none;

    &:last-child {
        border-bottom: 0;
    }

    > div {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;

        &,
        &:visited,
        &:active {
            text-decoration: none;
            color: #454443;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            text-transform: uppercase;
        }
    }

    &[data-active='true'] {
        background-color: #e4f4e8;
        border-left: solid 6px $dark-mint;
        font-weight: 800;
        border-bottom: none;

        > div {
            color: $dark-mint;
            font-weight: bold;
        }
    }
}

.siteItemLoading {
    composes: siteItem;
    @include animated-background-pulse(100% 100%);
}
.endpointItem {
    composes: siteItem;
    background: #f5f5f5;
    height: 40px;
    width: 100%;
    overflow: hidden;

    > div {
        &,
        &:visited,
        &:active {
            text-transform: lowercase;
            color: #454443;
            font-size: 16px;
            padding-right: 5px;
        }
    }

    &[data-active='true'] {
        border-left: solid 3px $dark-mint;

        > div {
        }
    }
}

.serviceItem {
    color: rgba($color: #000000, $alpha: 0.3);

    &:hover {
        background: $dark-mint;
    }
}

@keyframes show-endpoints-animation {
    0% {
        height: 0;
    }

    100% {
        height: 40px;
    }
}

.endpoints {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    > li {
        animation-name: show-endpoints-animation;
        animation-duration: 300ms;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
    }
}

.createNewSiteButton {
    @include not-active-button();
    width: 120px;
    align-self: end;
    margin: 10px auto;
}

.version {
    font-weight: 600;
    font-size: 12px;
    color: gray;
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
}
