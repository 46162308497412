@import 'style/helpers.scss';

.header {
    height: 46px;
    min-height: 46px;
    border-radius: 4px 4px 0 0;
    background-color: #fafafa;
    border-bottom: solid 1px #eeeeee;

    display: grid;
    grid-template-columns: min-content min-content min-content 1fr min-content;
    align-items: center;

    padding-left: 25px;
    padding-right: 30px;
}

.title {
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.7px;
    text-align: right;
    color: #303030;
    white-space: nowrap;
    text-transform: uppercase;
    margin-left: 30px;
}

.saveButton {
    @include active-button();
    margin-left: 10px;
}

.editIcon {
    width: 14px;
    height: 12px;
    vertical-align: middle;
    margin-right: 5px;
}

.editButton {
    @include active-button();
    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin-left: 10px;
    width: 120px;
    vertical-align: middle;
    font-weight: 600;
}

.undoButton {
    @include not-active-button();
    display: inline-block;
}

.total {
    grid-column-start: 5;
    white-space: nowrap;

    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    line-height: 2.17;
    letter-spacing: normal;
    color: rgba(black, 0.5);
}
