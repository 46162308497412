@import 'style/helpers.scss';

$padding-right: 12px;
$height: 42px;
$height-multiline: 157px;

.autoCompleteInput {
    display: inline-block;
    position: relative;
    height: $height;
    min-height: $height;
    // max-height: $height;
    min-width: 200px;

    &[data-is-multiline='true'] {
        height: $height-multiline;
        min-height: $height-multiline;
        max-height: $height-multiline;
        width: 100%;
    }

    :global {
        .rc-editor-mention-wrapper {
            @include input();
            width: 100%;
            height: 100%;
            background: white;
            padding-top: 15px;
        }
        .rc-editor-mention-wrapper.multilines {
            > .rc-editor-mention-editor {
                height: 100%;
                overflow-y: auto;
            }
        }
    }
}

.label {
    @include label();
    position: absolute;
    z-index: 100;
    right: $padding-right;
    top: 4px;
    text-align: left;
    font-size: 12px;
    user-select: none;
}

:global {
    .rc-editor-mention-dropdown {
        @include card3();
        // background: red;
        // border: 2px solid red;
        transform: translate(-100%, 0);
        z-index: 10000;
        border-radius: 4px;

        .rc-editor-mention-dropdown-item {
        }

        .rc-editor-mention-dropdown-item.focus {
            color: $dark-mint;
            font-weight: bold;
        }
    }
}
