@import 'style/helpers.scss';

.mapContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 400px;
}

.map {
    width: 100%;
    height: 100%;
    flex: 0 auto;
    border: 1px solid #e9e9e9;
}

.place {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    left: -10px;
    top: -10px;
    border: 1px solid blue;
    background: red;
    color: yellow;
}
