@import 'style/helpers.scss';

.title {
    background: #171c20;
    color: white;
    font-weight: bold;
    font-size: 16px;
    flex: 0 !important;
    text-align: left;
    height: 25px;
    min-height: 25px;
    line-height: 25px;
    padding-right: 20px;
    padding-left: 20px;
    flex: 0 auto;
}

.expandButton {
    position: absolute;
    z-index: 1000;
    top: -25px;
    right: 10px;

    background: none;
    border: none;
    color: white;
    outline: none;
    box-shadow: none;

    cursor: pointer;
    user-select: none;
    font-size: 16px;
    opacity: 0.8;

    * {
        color: white;
    }

    > i {
        margin-right: 10px;
    }
}

.aceEditorContainer {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 300px;

    &[data-is-dark='false'] {
        > button {
            > span {
                color: #171c20;
            }
            > i {
                color: #171c20;
            }
        }
    }
}

.codeEditor {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    //padding: 10px;
    //overflow: auto;
    direction: ltr !important;
    background: #1e1e1e;

    * {
        direction: ltr !important;
        //* {
        font-family: Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro,
            monospace !important;
        //}
    }

    > * {
        flex: 1;
        //width: 100% !important;
    }

    :global {
        .codicon {
            font-family: codicon !important;
        }
    }
}

.codeEditorExpanded {
    composes: codeEditor;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2000;
    top: 0;
    right: 0;
    margin-top: 0;
}

.commands {
    display: flex;
    align-items: center;

    padding-left: 20px;
    flex: 0 auto;
    padding-bottom: 5px;
    padding-top: 5px;

    min-height: 30px;

    > * {
        margin-right: 15px;
    }

    > button {
        border: none;
        background: none;
        box-shadow: none;
        outline: none;
        color: white;
        cursor: pointer;
        user-select: none;
        text-decoration: underline;
        font-weight: bold;
    }

    &[data-is-dark='true'] {
        background: #202328;
        border-bottom: #2a2b30;
    }

    &[data-is-dark='false'] {
        background: #ffffff;
        border-bottom: #ffffff;
    }
}
