@import 'style/helpers.scss';

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 43px;
    border-radius: 4px 4px 0 0;
    background-color: #fefefe;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-bottom: solid 1px #eeeeee;
}

.item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    flex: 0 auto;
    min-width: 148px;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: rgba(black, 0.5);
    height: 100%;

    user-select: none;
    cursor: pointer;

    text-transform: uppercase;

    padding-left: 20px;
    padding-right: 20px;

    &:hover {
        color: rgba(black, 0.7);
    }

    &:after {
        content: '';
        display: block;
        width: 1px;
        height: 12px;
        background: #ababab;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 14px;
    }

    &:last-of-type {
        &:after {
            display: none;
        }
    }

    &:first-of-type {
        padding-right: 32px;
    }

    > span {
        min-width: 95px;
    }
}

.activeItem {
    composes: item;
    color: #000000;

    > span {
        height: 100%;
        line-height: 40px;
        border-bottom: solid 3px $dark-mint;
    }

    &:hover {
        color: rgba(black, 1);
    }
}
