@import 'style/helpers.scss';

.outterTableContainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    //border: 1px solid red;
}

@media (max-width: 524px) {
    .outterTableContainer {
        height: unset;
    }
}

.tableContainer {
    flex: 1;
    display: block;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
    margin-top: -5px; /* correct offset on first border spacing if desired */

    thead {
        tr:nth-child(2) {
            td:first-of-type {
                [class^='style_headerSort'] {
                    margin-right: 1em;
                }
            }
        }
    }

    tbody {
        td {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &[data-column-type='image'] {
                width: 80px;
                max-width: 80px;
            }
            &[data-column-type='date'] {
                width: 140px;
                max-width: 140px;
            }

            &[data-column-type='boolean'] {
                width: 120px;
                max-width: 120px;
            }

            &:last-child {
                width: unset !important;
                max-width: unset !important;
            }
        }
    }
}

.spacer {
    //flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 1em;
    position: absolute;
    top: 50%;
    width: 100%;

    > div {
        > div {
            width: 20px;
            height: 20px;
            margin: 4px;
        }
    }
}

.notFoundBlock {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
}
