@import 'style/helpers.scss';

.dateFilter {
    position: relative;
    width: 100%;
    z-index: 1;
    float: right;
}

.currentValueContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    > div:nth-of-type(1) {
        margin-left: 10px;
    }

    padding-left: 15px;
}

.currentValue {
    flex: 1;
    height: 28px;
    border-radius: 4px;
    background-color: #f6f6f6;

    //width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;

    &:hover {
        font-weight: 600;
        background-color: darken(#f6f6f6, 10%);
    }
}

.currentValueOpened {
    composes: currentValue;
    border: 2px solid $dark-mint;
}

.menu {
    @include group();
    @include card5();
    position: absolute;
    top: 32px + 5px;
    background: white;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    max-height: 450px;
    display: none;
    padding-top: 10px;
    //overflow: hidden;
    padding: 15px;

    display: flex;
    flex-direction: column;

    &[data-is-right-side='true'] {
        left: 0;
    }

    &[data-is-right-side='false'] {
        right: 0;
    }
}

.menuOpened {
    composes: menu;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.filtered {
    line-height: 28px;
    font-weight: 600;
    font-size: 13px;
    white-space: nowrap;
    color: $white;
    width: 100%;
    height: 100%;
    background: $dark-mint;
    text-align: center;
    border-radius: 4px;
    letter-spacing: 0;
    direction: ltr;

    &:hover {
        background: darken($dark-mint, 20%);
    }
}

.dates {
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
        margin-left: 10px;
    }
}

.buttons {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    justify-content: center;

    > div {
        margin-left: 10px;
    }
}

.cancelButton {
    @include not-active-button();
}

.applyButton {
    @include active-button();
    width: 120px;
}

.input {
    direction: ltr;

    input {
        direction: ltr;
    }
}

.errorInput {
    composes: input;
    border: 1px solid $tomato !important;
    box-shadow: inset 0px 0px 0px 1px $tomato !important;
}
