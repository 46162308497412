@import 'style/helpers.scss';

$padding-right: 12px;
$height: 42px;

.label {
    @include label();
    position: absolute;
    z-index: 100;
    right: $padding-right;
    top: 4px;
    text-align: left;
    font-size: 12px;
}

.input {
    @include input();
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #444d45;

    background: white;
    padding-top: 16px;
    padding-right: $padding-right;
    font-weight: 500;
    //background: #f2f2f2;
    max-width: 600px;

    &:focus {
        background: white;
    }

    &[type='password'] {
        font-size: 20px;
        font-weight: 300;
        font-stretch: expanded;
    }
}

.inputContainer {
    display: inline-block;
    position: relative;
    height: $height;
    min-height: $height;
    max-height: $height;
    min-width: 300px;

    &[data-is-multiple='true'] {
        max-height: unset;
        height: unset;
        min-height: $height + 10px;
        max-width: unset;

        > div {
            height: 100%;
        }

        :global {
            .select-input__value-container {
                padding-top: 15px;
            }
        }
    }

    :global {
        .select-input__control {
            //width: 113px;
            border-radius: 4px !important;
            background-color: white;

            position: absolute !important;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid $input-border-color !important;
        }

        .select-input-error__control {
            //width: 113px;
            border-radius: 4px !important;
            background-color: white;

            position: absolute !important;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: solid 2px $tomato !important;
        }

        .select-input__control.select-input__control--is-focused {
            //width: 113px;
            outline: none !important;

            position: absolute !important;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid transparent !important;
            box-shadow: inset 0 0 0 2px $dark-mint !important;
        }

        .select-input-error__control.select-input-error__control--is-focused {
            //width: 113px;
            border-radius: 4px !important;
            outline: none !important;
            border: 1px solid transparent !important;
            box-shadow: inset 0 0 0 2px $tomato !important;

            position: absolute !important;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .select-input__control-separator {
            display: none;
        }

        .select-input__control--is-disabled {
            background-color: hsl(0, 0%, 95%);
        }

        .select-input__value-container {
            font-size: 16px;
            outline: none;
        }

        .select-input__indicator-separator {
            margin-top: 6px !important;
        }

        .select-input__control.select-input__control--is-disabled {
            background-color: $disabled-input-background;
            border: solid 1px #e6e6e6;
            color: $charcoal-grey;
        }

        .select-input__single-value,
        .select-input__placeholder,
        .select-input-error__placeholder {
            padding-top: 15px;
            font-weight: 500;
        }

        .select-input__single-value.select-input__single-value--is-disabled {
            color: $charcoal-grey;
        }

        .react-select__option {
            font-size: 16px;
        }

        .select-input__indicator.select-input__dropdown-indicator {
            font-size: 13px;
            color: $dark-mint;
        }

        .select-input__indicators {
            height: 100% !important;
        }

        .select-input__value-container {
            padding: 0;
            padding-right: 8px;
            height: 100% !important;
        }

        .select-input__menu,
        .select-input-error__menu {
            top: 32px;
            z-index: 1500;
        }
    }
}
