.container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    .deleteContainer {
        flex: 0.5;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .deleteButton {
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            align-self: center;
        }
    }

    .coverPhotoContainer {
        width: 100%;
        height: 100%;
        flex: 1.2;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .coverPhoto {
            width: 37px;
            height: 37px;
            border-radius: 100%;
            background: grey;

            img {
                width: 100%;
            }
            overflow: hidden;
        }
    }
    .commentWrapper {
        width: 100%;
        height: 100%;
        flex: 10;
        .commentContainer {
            width: fit-content;
            height: auto;
            background: #e0e0e02e;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-right: 8px;
            padding-left: 8px;
            padding-top: 8px;
            padding-bottom: 8px;
            word-wrap: break-word;
        }
    }
}
