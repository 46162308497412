@import 'style/helpers.scss';

.title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
}

.optionsTitle {
    font-weight: bold;
}

.fieldsGroup {
    display: block;
    flex: 1;
    overflow-y: auto;
    padding-left: 15px;

    > *[data-role='view'] {
        margin-bottom: 10px;
        width: 100%;
    }
}
