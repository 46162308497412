@import 'style/helpers.scss';

.cellArray {
    width: 100%;
    height: 35px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &[data-update-in-place='true'] {
    }
}
