@import 'style/helpers.scss';

.logo {
    justify-self: center;
    margin-top: 42px;
    width: 147px;
    height: 42px;
    padding: 12px 12px 12px 12px;
    border-radius: 24.5px;
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    direction: ltr;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
}
