@font-face {
    font-family: 'Menlo';
    src: font-url('../../assets/fonts/Menlo/Menlo-Italic.eot');
    src: font-url('../../assets/fonts/Menlo/Menlo-Italic.eot?#iefix')
            format('embedded-opentype'),
        font-url('../../assets/fonts/Menlo/Menlo-Italic.woff2') format('woff2'),
        font-url('../../assets/fonts/Menlo/Menlo-Italic.woff') format('woff'),
        font-url('../../assets/fonts/Menlo/Menlo-Italic.ttf') format('ttf');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Menlo';
    src: font-url('../../assets/fonts/Menlo/Menlo-BoldItalic.eot');
    src: font-url('../../assets/fonts/Menlo/Menlo-BoldItalic.eot?#iefix')
            format('embedded-opentype'),
        font-url('../../assets/fonts/Menlo/Menlo-BoldItalic.woff2')
            format('woff2'),
        font-url('../../assets/fonts/Menlo/Menlo-BoldItalic.woff')
            format('woff'),
        font-url('../../assets/fonts/Menlo/Menlo-BoldItalic.ttf') format('ttf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Menlo';
    src: font-url('../../assets/fonts/Menlo/Menlo-Bold.eot');
    src: font-url('../../assets/fonts/Menlo/Menlo-Bold.eot?#iefix')
            format('embedded-opentype'),
        font-url('../../assets/fonts/Menlo/Menlo-Bold.woff2') format('woff2'),
        font-url('../../assets/fonts/Menlo/Menlo-Bold.woff') format('woff'),
        font-url('../../assets/fonts/Menlo/Menlo-Bold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Menlo';
    src: font-url('../../assets/fonts/Menlo/Menlo-Regular.eot');
    src: font-url('../../assets/fonts/Menlo/Menlo-Regular.eot?#iefix')
            format('embedded-opentype'),
        font-url('../../assets/fonts/Menlo/Menlo-Regular.woff2') format('woff2'),
        font-url('../../assets/fonts/Menlo/Menlo-Regular.woff') format('woff'),
        font-url('../../assets/fonts/Menlo/Menlo-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}
