@import 'style/helpers.scss';

.changePasswordDialog {
    composes: dialogOpened from '../../../components/native/dialog/style.module.scss';
    width: 350px;
    height: 300px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    > div {
        width: 100%;
        margin-bottom: 10px;
    }
}

.submitButton {
    margin-top: 10px;
    margin-left: 10px;
    @include active-button();
}

.closeButton {
    margin-top: 10px;
    margin-left: 10px;
    @include not-active-button();
}

.buttonsGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
