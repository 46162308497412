@import 'style/helpers.scss';

// .cell{
//   text-align: right;

// :global {
//   .select-input__control {

//     border-radius: 4px !important;
//     background-color: unset;

//     border: none !important;
//     border-color: none !important;
//     border-width: 0 !important;
//   }

//   .select-input-error__control {
//     border-radius: 4px !important;
//     background-color: white;
//     border: solid 2px $tomato !important;
//   }

//   .select-input__control.select-input__control--is-focused {

//     outline: none !important;

//     border: 1px solid transparent !important;
//     box-shadow: inset 0 0 0 2px transparent !important;
//   }

//   .select-input-error__control.select-input-error__control--is-focused {

//     border-radius: 4px !important;
//     outline: none !important;
//     border: 1px solid transparent !important;
//     box-shadow: inset 0 0 0 2px $tomato !important;
//   }

//   .select-input__control-separator {
//     display: none;
//   }

//   .select-input__control--is-disabled {
//     background-color: hsl(0, 0%, 95%);
//   }

//   .select-input__value-container {
//     font-size: 16px;
//     outline: none;
//     >div{
//       color: unset;
//     }
//   }

//   .select-input__indicator-separator {

//   }

//   .select-input__control.select-input__control--is-disabled {
//     background-color: $disabled-input-background;
//     border: solid 1px #e6e6e6;
//     color: $charcoal-grey;
//   }

//   .select-input__single-value, .select-input__placeholder, .select-input-error__placeholder {
//     font-weight: 500;
//   }

//   .select-input__single-value.select-input__single-value--is-disabled {

//   }

//   .react-select__option {
//     font-size: 16px;
//   }

//   .select-input__indicator.select-input__dropdown-indicator {
//     margin-top: 5px;
//     font-size: 13px;
//     color: $dark-mint;
//   }

//   .select-input__indicators {
//     height: 100% !important;
//   }

//   .select-input__value-container {
//     padding: 0;
//     padding-right: 8px;
//     height: 100% !important;
//   }

//   .select-input__menu, .select-input-error__menu {
//     z-index: 1500;
//     color: $charcoal-grey;
//   }
// }
// }

// .cellError{
//   composes: cell;
//   color: red;
//   font-weight: 700;
// }

// $padding-right: 12px;
// $height: 42px;

// .input {
//   @include input();
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   font-size: 15px;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   text-align: right;
//   color: #444d45;

//   background: white;
//   padding-top: 16px;
//   padding-right: $padding-right;
//   font-weight: 500;
//   max-width: 600px;

//   &:focus {
//     background: white;
//   }

//   &[type="password"] {
//     font-size: 20px;
//     font-weight: 300;
//     font-stretch: expanded;
//   }
// }

// .editContainer {

// }

@import 'style/helpers.scss';

.enumCell {
    width: 100%;
    height: 35px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
    text-overflow: ellipsis;
    overflow: visible;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > span {
        text-align: right;
    }
}
