@import 'style/helpers.scss';

.textCell {
    width: 100%;
    height: 35px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &[data-update-in-place='true'] {
    }
}

.input {
    height: 95%;
    border: none;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    width: 100%;
    background: white;

    &:focus {
        border: solid 2px $dark-mint;
    }

    &:hover {
        border: solid 2px $dark-mint;
    }

    &[data-processing='true'] {
        background: red;
    }
}
