@import 'style/helpers.scss';

.container {
    display: inline-block;
    padding: 4px;
}

.box {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: #ebebeb;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    user-select: none;
    cursor: pointer;

    overflow: hidden;
}

.boxCheck {
    composes: box;

    > i {
        font-size: 26px;
        color: $dark-mint;
        line-height: 1;
    }
}

.boxIntermediate {
    composes: box;

    > i {
        font-size: 15px;
        color: rgba($dark-mint, 0.5);
    }
}
