@import 'style/helpers.scss';

.container {
    display: grid;
    justify-items: center;
    justify-content: center;
    height: 341px;
    grid-template-rows: 1fr 20px;
}

.logoImage {
    width: 140px;
    height: 56px;
    align-self: start;
    justify-self: center;
    margin-top: 25px;
}

.linksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > a {
        &,
        &:visited {
            text-decoration: none;
            font-size: 14px;
            color: #454443;
            font-family: Aran;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 2;
            letter-spacing: 0.3px;
        }
    }
}

.bullet {
    font-size: 18px;
    color: $green;
}
