@import 'style/helpers.scss';

.uploadView {
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 4px;
    background-color: #ffffff;
    border: dashed 1px $dark-mint;

    user-select: none;

    &:hover {
    }
}

.selectedStub {
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 4px;
    background-color: white;
    border: dashed 1px $dark-mint;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $dark-mint;
    font-weight: bold;

    user-select: none;

    &:hover {
    }

    > button {
        @include not-active-button();
        margin-top: 15px;
    }
}

.uploadViewHover {
    composes: uploadView;
    border: dashed 2px $dark-mint;
}

.uploadViewError {
    composes: uploadView;
    border: dashed 2px $tomato;
}

.content {
    width: 100%;
    height: 100%;
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon {
    margin-bottom: 10px;
    max-width: 30px;
    max-height: 30px;
}

.text {
    direction: rtl;
    white-space: pre-line;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: rgba(#798a7b, 0.6);
    margin-bottom: 8px;
}

.browseButton {
    width: 81px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    background-color: #f4faf5;
    border: solid 1px $dark-mint;

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    letter-spacing: normal;
    color: $dark-mint;

    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: darken(#f4faf5, 10%);
    }
}

.browseButtonError {
    composes: browseButton;
    //color: $tomato;
    //border: 1px solid $tomato;
    //background-color: #facac8;
}

.extensions {
    font-weight: bold;
    direction: ltr;
}
