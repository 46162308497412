@import 'style/helpers.scss';

.commandPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: white;

    > * {
        margin-left: 15px;
    }
    > *:last-child {
        margin-left: 0;
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    background: #f4f4f4;
    padding: 10px;
    border: 1px solid #eee;
    margin-top: 10px;

    > *[data-role='view'] {
        margin-bottom: 10px;
    }
}

.propertiesTablePanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    background: white;
    padding: 10px;
    border-radius: 4px;
}

.propertiesTableTitle {
    font-size: 22px;
    text-align: right;
    flex: 1;
    font-weight: bold;
}
