@import 'style/helpers.scss';

.container {
    height: 50px;

    display: grid;
    grid-template-columns: min-content min-content auto min-content min-content;
    column-gap: 10px;
    align-items: center;

    width: 100%;
    padding-left: 60px;
    padding-right: 60px;

    background-color: #fefefe;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    position: fixed;
    bottom: 0;
    left: 0;
}

.activeButton {
    @include active-button();
}

.notActiveButton {
    @include not-active-button();
}
