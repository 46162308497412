@import 'style/helpers.scss';

.dialog {
    width: 90%;
    height: 90%;
}

.tableContainer {
    width: 100%;
    flex: 1;

    > * {
        width: 100%;
    }
}
