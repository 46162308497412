@import 'style/helpers.scss';

.cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px;
    max-width: 50px;

    > div {
        height: 16px;
        // padding: 0;
    }
}
