@import 'style/helpers.scss';

.urlInputWithParams {
    display: inline-flex;
    position: relative;

    > div:first-of-type {
        width: 100%;

        input {
            padding-left: 230px;
        }

        > label {
        }
    }
}

.buttons {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    left: 10px;
    top: 6px;
    position: absolute;
    z-index: 0;

    > button:first-of-type {
        margin-left: 6px;
    }
}

.selectButton {
    @include not-active-button();
    z-index: 3000;
    height: unset;
    line-height: 22px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 100px;
    width: unset;
    margin-top: 4px;
}
