@import 'style/helpers.scss';

.cell {
    height: 100%;
}

.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.progressContainer {
    position: relative;
    width: 76px;
    height: 19px;
    overflow: hidden;
    border-radius: 4px;
}

.progressBar {
    width: 76px;
    border-radius: 4px;
    background-color: #f2f2f2;
    border: solid 1px #ededed;
    height: 100%;
    overflow: hidden;

    > div {
        height: 100% !important;
        background: #ffbd51 !important;
    }
}

.numbers {
    display: block;
    position: absolute;
    top: 0;
    margin: 0 auto;
    text-align: center;
    color: white;
    line-height: 19px;
    width: 100%;

    font-size: 14px;
    font-weight: 500;
    font-style: oblique;
    font-stretch: normal;
    letter-spacing: 1px;
    mix-blend-mode: difference;
}
