@import 'style/helpers.scss';

.mediaPhotoItem {
    display: inline-block;
    padding-left: 10px;
}

.item {
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #ededed;
    user-select: none;
    cursor: pointer;

    overflow: hidden;

    //> div:nth-of-type(1) {
    //  display: none;
    //}

    &:hover {
        > div {
            display: flex;
        }
    }
}

.itemUploading {
    composes: item;

    > div:nth-of-type(1) {
        display: flex;
    }
}

:global(.moving) {
    .item {
        opacity: 0.7;
        z-index: 3000 !important;

        &:hover {
            > div {
                display: none;
            }
        }
    }
}

.photo {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
    background-color: #f5f5f5;
    padding: 10px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.2);
}

.uploading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.5);
    color: white;
    font-size: 16px;
    padding-bottom: 25px;

    > span {
        margin-bottom: 5px;
    }
}

.deleteButton {
    @include not-active-button();
    display: inline-block;
    margin-top: 20px;

    &:hover {
        background-color: #fefefe;
        color: #798a7b;
    }
}

.handle {
    position: absolute;
    left: 10px;
    top: 5px;
    color: white;
    font-size: 25px;
    cursor: pointer;
    user-select: none;
}

.showButton {
    font-size: 25px;
    color: white;
    right: 10px;
    top: 10px;
    position: absolute;
    cursor: pointer;
    user-select: none;
}

.downloadButton {
    font-size: 25px;
    color: white;
    right: 40px;
    top: 10px;
    position: absolute;
    cursor: pointer;
    user-select: none;
}

.videoContent {
    background: $charcoal-grey;

    > video {
        height: 100%;
        width: 100%;
    }
}
