@import 'style/helpers.scss';

input:focus::-webkit-input-placeholder {
    color: transparent;
}

.inputs {
    > div:first-of-type {
        margin-left: 15px;
    }
}

.loginCheckbox {
    margin: 30px 0 83px 0;
}

.submitButton {
    display: inline-block;
    width: 106px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    background-color: #43bf60;
    box-shadow: 0 6px 8px 0 #b6f3c4;

    color: $white;
    border: 0;

    text-align: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;

    user-select: none;
    cursor: pointer;

    &:hover {
        background-color: #3bb15d;
        cursor: pointer;
    }
}

.submitButtonProcessing {
    composes: submitButton;
    pointer-events: none;

    > span {
        display: none;
    }

    > div {
        margin-top: 5px;
        transform: scale(0.8);
    }
}

.rememberMe {
    margin-right: 20px;
    font-family: Avenir, sans-serif;
    font-size: 12px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #444d45;
}

.processingContainer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(white, 0.7);
    z-index: 1000;
}
