@import 'style/helpers.scss';

.pageHeader {
    @include card1();
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 65px;
    padding-left: 30px;
    padding-right: 30px;

    background-color: #fcfcfc;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
    // z-index: 500;
    position: relative;

    > * {
        margin-left: 10px;
    }

    > *:last-child {
        margin-left: 0;
    }
}

.headerTitle {
    font-size: 22px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.9px;
    text-align: right;
    color: #303030;
    white-space: nowrap;
    text-transform: uppercase;
}

.headerSubtitle {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.9px;
    text-align: right;
    color: rgba(#303030, 0.5);
    white-space: nowrap;
}

.searchContainer {
    flex: 1;
    align-items: center;
    position: relative;
    margin-right: 10px;

    > i {
        color: $dark-mint;
        position: absolute;
        left: 10px;
        top: 7px;
        font-size: 20px;
        cursor: pointer;
        user-select: none;
    }
}

.hamburgerMenuContainer {
    display: none;
}

@media (max-width: 524px) {
    .pageHeader {
        overflow-x: auto;
        height: 50px;
        padding-left: 15px;
        padding-right: 40px;
    }

    .headerTitle {
        font-size: 15px;
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10px;
    }

    .hamburgerMenuContainer {
        display: block;
        position: fixed;
        right: 15px;
        padding-top: 2px;
    }
}
