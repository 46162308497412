@import 'style/helpers.scss';

$padding-right: 12px;

.container {
    display: inline-block;
    position: relative;
    height: 157px;
    min-height: 157px;
    max-height: 157px;
}

.label {
    @include label();
    position: absolute;
    z-index: 100;
    right: $padding-right;
    top: 4px;
    text-align: left;
    font-size: 12px;
}

.textarea {
    @include input();
    position: absolute;
    width: 100%;
    height: 100%;
    color: #444d45;
    overflow: auto;
    //max-width: 600px;

    background: white;
    padding-top: 12px;
    padding-right: $padding-right;

    &:focus {
        background: white;
    }

    &:disabled {
        background-color: $disabled-input-background;
    }

    &:focus {
        border: 2px solid $dark-mint;
    }
}
