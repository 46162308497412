@import 'style/helpers.scss';

.commandButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &[button-role='active'] {
        height: 40px;
        min-width: 100px;
        border-radius: 4px;
        background-color: $dark-mint;
        margin-right: 15px;
        border: none;
        color: white;
        cursor: pointer;
        user-select: none;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 15px;

        &:hover {
            background-color: #3cab4a;
        }
    }

    &[button-role='notActive'] {
        @include not-active-button();

        padding-left: unset;
        padding-right: unset;

        img {
            filter: invert(23%) sepia(0%) saturate(0%) hue-rotate(150deg)
                brightness(96%) contrast(86%);
        }
    }

    div {
        margin-right: 10px;
        margin-left: unset;
    }

    div:first-child:nth-last-child(1) {
        margin-left: 10px;
        margin-right: 10px;
    }

    > img {
        margin-left: unset;
        margin-right: unset;
        height: 100%;
        padding: 5px;
    }
}

@media (max-width: 524px) {
    .commandButton {
        border: none;
        background: none;
        box-shadow: none;

        &[data-has-image='true'] {
            > div {
                display: none;
            }
        }
    }
}
