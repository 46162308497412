@import 'style/helpers.scss';

.intervalInputContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    padding: 5px;
    background: white;
}

.label {
    @include label();
    font-size: 12px;
    user-select: none;
    margin-bottom: 15px;
}
