@import 'style/helpers.scss';

.intervalInput {
    display: inline-grid;
    grid-auto-flow: column;
    grid-template-rows: min-content min-content;
    grid-template-columns: min-content min-content min-content min-content min-content min-content;
    grid-column-gap: 10px;
    grid-row-gap: 5px;

    > label {
        @include label();
        padding-left: 10px;
        white-space: nowrap;
    }
}

.input {
    @include input();
    height: 28px;
    width: 80px;
}

.inputError {
    composes: input;

    border: none;
    box-shadow: 0 0 0 2px $tomato;

    &:hover {
        border: none;
    }
}
