@import 'style/helpers.scss';

.verticalGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    > *[data-role='view'] {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
