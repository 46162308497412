@import 'style/helpers.scss';

.counter {
    display: inline-flex;
    flex-direction: column;
    background-color: #ebebeb;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-bottom: 20px;
    min-width: 130px;
}

.label {
    color: #7f7f7f;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-top: 6px;
}

.value {
    font-weight: 700;
    font-size: 30px;
    min-height: 35px;
    text-align: center;
    margin-top: 3px;
}

.description {
    color: #7f7f7f;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-bottom: 3px;
}
