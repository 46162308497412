@import 'style/helpers.scss';

.app {
    flex: 1;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.development {
    position: fixed;
    font-size: 20px;
    font-weight: bold;
    color: red;
    bottom: 0;
    left: 10px;
    z-index: 2000;
    pointer-events: none;
    user-select: none;
}
