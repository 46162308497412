@import 'style/helpers.scss';

.processing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(white, 0.5);
    z-index: 100;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div:nth-child(2) {
        > div {
            width: 20px;
            height: 20px;
            margin: 4px;
        }
    }
}

.progressText {
    margin-bottom: 10px;
}
