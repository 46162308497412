@import 'style/helpers.scss';

.dialog {
    width: unset;
    height: unset;
    min-width: 300px;

    > div:nth-of-type(2) {
        overflow: visible;
    }
}
