@import 'style/helpers.scss';

.searchContainer {
    flex: 1;
    align-items: center;
    position: relative;
    margin-right: 10px;
    min-width: 300px;
    width: 100%;
    > i {
        color: $dark-mint;
        position: absolute;
        left: 10px;
        top: 7px;
        font-size: 20px;
        cursor: pointer;
        user-select: none;
    }
}

@media (max-width: 524px) {
    .searchContainer {
        min-width: unset;
    }
}

.searchInput {
    width: 100%;
    height: 32px;
    outline: none;

    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #ededed;

    padding-right: 15px;
    padding-left: 30px;

    &:focus {
        border: 2px solid $dark-mint;
    }
}
