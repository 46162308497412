@import 'style/helpers.scss';

.chartContainer {
    @include card5();
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    max-height: 600px;
    border-radius: 10px;
    padding-right: 50px;
}

.toptopRow {
    display: flex;
    padding-bottom: 15px;

    > div {
        margin-left: 15px;
    }
}
.topRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
}

.currentChartTotalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 auto;
    padding: 20px;
}

.currentChartTotal {
    font-size: 50px;
    font-weight: bold;
}

.currentChartTotalLabel {
    font-weight: bold;
    font-size: 20px;
    color: $dark-mint;
}

.chart {
    position: relative;
    // flex: 1;
    // height: 100%;
    // direction: rtl;
    height: 400px;
    width: 100%;

    // * {
    //     direction: rtl;
    // }
}

.bottomRow {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
}

.endpointTab {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    cursor: pointer;
    user-select: none;
    &[data-active='true'] {
        border-bottom: 3px solid $dark-mint;
    }
}

.endpointTotal {
    font-size: 40px;
    color: $dark-mint;
    font-weight: bold;
}

.endpointTitle {
    font-weight: bold;
    color: $dark-mint;
}

.dataStub {
    color: $dark-mint;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 30px;
    color: black;
}
