@import 'style/helpers.scss';

.items {
    direction: ltr;
    display: flex;
    flex-direction: row;

    > :global(.moved) {
        opacity: 0.5;
    }
}
