@import 'style/helpers.scss';

.referenceCell {
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
    text-overflow: ellipsis;
    overflow: hidden;

    a {
        &,
        &:active,
        &:visited {
            color: $dark-mint;
        }
    }
}
