@font-face {
    font-family: 'Aran';
    font-weight: 100;
    src: url('../../assets/fonts/OSAran/osaran100-web.woff');
}

@font-face {
    font-family: 'Aran';
    font-weight: 200;
    src: url('../../assets/fonts/OSAran/osaran200-web.woff');
}

@font-face {
    font-family: 'Aran';
    font-weight: 300;
    src: url('../../assets/fonts/OSAran/osaran300-web.woff');
}

@font-face {
    font-family: 'Aran';
    font-weight: 400;
    src: url('../../assets/fonts/OSAran/osaran400-web.woff');
}

@font-face {
    font-family: 'Aran';
    font-weight: 500;
    src: url('../../assets/fonts/OSAran/osaran500-web.woff');
}

@font-face {
    font-family: 'Aran';
    font-weight: 600;
    src: url('../../assets/fonts/OSAran/osaran600-web.woff');
}

@font-face {
    font-family: 'Aran';
    font-weight: 700;
    src: url('../../assets/fonts/OSAran/osaran700-web.woff');
}

@font-face {
    font-family: 'Aran';
    font-weight: 800;
    src: url('../../assets/fonts/OSAran/osaran800-web.woff');
}
