@import 'style/helpers.scss';

$padding-right: 12px;
$height: 42px;

.inputContainer {
    display: inline-block;
    position: relative;
    height: $height;
    min-height: $height;
    max-height: $height;
    min-width: 300px;
}

.label {
    @include label();
    position: absolute;
    z-index: 100;
    right: $padding-right;
    top: 4px;
    text-align: left;
    font-size: 12px;
}

.input {
    @include input();
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: right;
    color: #444d45;

    background: white;
    padding-top: 16px;
    padding-right: $padding-right;
    //font-weight: bold;
    //background: #f2f2f2;
    //max-width: 600px;

    &:focus {
        background: white;
    }

    &[type='password'] {
        font-size: 20px;
        font-weight: 300;
        font-stretch: expanded;
    }
}

.calendarIcon {
    position: absolute;
    right: $padding-right;
    z-index: 1000;
    height: 14px;
    width: 14px;
    top: 22px;
}

.datePicker {
    > input {
        padding-right: 35px;
    }
}
