@import 'style/helpers.scss';

.imageCell {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;

    > img {
        width: 56px;
        height: 32px;
        border-radius: 4px;
        background-color: #fafafa;
        object-fit: contain;
        margin-left: 10px;
        border: 1px solid #e0e0e0;
    }
}
