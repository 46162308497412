@import 'style/helpers.scss';

.colorCell {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    text-transform: lowercase;
}

.color {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    object-fit: contain;
    margin-left: 10px;
    border: 1px solid #e0e0e0;
}
