@import 'style/helpers.scss';

.title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
}

.optionsTitle {
    font-weight: bold;
}

.fieldsGroup {
    display: block;
    flex: 1;
    overflow-y: auto;
    padding-left: 15px;

    > *[data-role='view'] {
        margin-bottom: 10px;
        width: 100%;
    }
}

:global {
    #confirm-production-endpoint-script-change {
        > div {
            max-height: 300px;
        }
    }
}

.logItem {
    @include card1();
    width: 100%;
    display: inline-block;
    padding: 15px;
    background: white;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #fefefe;
    text-align: right;
    white-space: pre-wrap;
}
