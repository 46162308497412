@import 'style/helpers.scss';

.innerItems {
    display: flex;
    flex-direction: column;

    border: 1px solid #d6d6d6;
    padding: 5px;
    border-radius: 4px;
    background: white;
    min-height: 300px;
    max-height: 500px;
}

.label {
    @include label();
}

.tableContainer {
    padding-top: 10px;
    thead > tr:nth-of-type(1) {
        background: #f5f5f5;
        // position: sticky;
        // top: 0;
    }

    thead > tr:nth-of-type(2) {
        display: none;
    }

    thead > tr:nth-of-type(1) > td {
        position: sticky;
        top: 0;
        background: #f5f5f5;
    }

    > div {
        > div:nth-of-type(1) {
            position: relative;
            overflow: auto;
            max-height: 400px;
        }

        > div:nth-of-type(2) {
            display: none;
        }
    }
}

.commandPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > button {
        margin-right: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.addButton {
    @include not-active-button();
    // position: absolute;
    // left: 50px;
    // top: 10px;
    z-index: 3000;
    height: unset;
    line-height: 22px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 100px;
    width: unset;
    margin-top: 4px;
}

.orderButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    > svg {
        height: 20px;
        width: 20px;
        padding: 2px;
        cursor: pointer;
        user-select: none;
    }
}

.deleteButton {
    @include not-active-button();
}
