@import 'style/helpers.scss';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    height: 100%;
    padding-left: 216px;
    padding-right: 30px;
    box-sizing: border-box;
    background: #fafafa;
}

.formContainer {
    display: grid;
    justify-items: center;
    justify-content: flex-start;
    height: 341px;
    grid-template-rows: 1fr 1fr 1fr;
    background: #fafafa;
}

.loginTitles {
    align-items: start;
}

.headerTitle {
    width: 76px;
    height: 16px;
    opacity: 0.4;
    font-family: Avenir, sans-serif;
    font-size: 12px;
    font-weight: 800;
    line-height: 1.33;
    letter-spacing: 1px;
    color: #303030;
    margin-bottom: 8px;
}

.subTitle {
    width: 320px;
    height: 16px;
    font-family: Avenir, sans-serif;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.89;
    letter-spacing: 1px;
    text-align: right;
    color: #303030;
}

.footer {
    display: grid;
    grid-gap: 10px;
    font-size: 12px;

    .copyrights {
        opacity: 0.4;
        font-family: Muli, sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #454443;
    }

    .email {
        font-family: Muli, sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #454443;
        align-self: center;
    }
}
