.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background: white;

    > * {
        margin-left: 15px;
    }
    > *:last-child {
        margin-left: 0;
    }
}
