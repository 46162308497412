@import 'style/helpers.scss';

.htmlEditorWrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: #fafafa;
    box-shadow: 0 5px 50px #ededed;
    animation: zoom 0.4s forwards ease 1;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    z-index: 99999;

    padding: 30px;

    .editor {
        padding-bottom: 100px;
        > div:first-child {
            height: unset;
        }
    }

    > div:last-child {
        z-index: 99999999;
        position: fixed;
        bottom: 0;
    }
}

.htmlEditor {
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 99999;
}

@keyframes zoom {
    from {
        top: 100%;
    }
    to {
        top: 0px;
    }
}

.toolbar {
}
