@import 'style/helpers.scss';

.item {
    display: inline-block;
    background: $dark-mint;
    border-radius: 4px;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    border: none;
    line-height: 1;

    &:hover {
        background: darken($color: $dark-mint, $amount: 10%);
    }
}
