@import 'style/helpers.scss';

.cronBuilder {
    direction: ltr;
    border: 1px solid #d6d6d6;
    background: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5px;

    * {
        direction: ltr;
    }

    > div {
        background: white;
    }

    :global {
        .cron-builder {
            width: 100%;
        }
        .cron-builder__fieldset {
            border-color: $dark-mint;
        }

        .cron-builder__pretty-expression {
            background: white;
            color: $dark-mint;
            text-align: center;
            border-radius: 4px;
            padding: 10px;
            max-width: 300px;
            margin: 0 auto;
            border: 1px dashed $dark-mint;
        }

        .cron-builder__fieldset {
            width: 100%;
            min-width: 100%;
        }

        .cron-builder__result {
            display: none;
        }

        .cron-builder__action {
            @include active-button();
            background-image: none;
        }
    }
}
