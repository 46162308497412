@import 'style/helpers.scss';

.headerSort {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    float: right;

    cursor: pointer;
    user-select: none;

    > img:nth-of-type(1) {
        margin-left: 2px;
    }
}
