@import 'style/helpers.scss';

.phoneCell {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.linkCell {
    color: $dark-mint;
    text-decoration: underline;
}
