@import './helpers.scss';
@import './datepicker.scss';
@import './bootstrap.scss';
@import './fonts/aran.scss';
@import './fonts/menlo.scss';

@font-face {
    font-family: 'Assistant';
    font-weight: 300;
    src: url('../assets/fonts/Assistant/Assistant-ExtraLight.ttf');
}

@font-face {
    font-family: 'Assistant';
    font-weight: 400;
    src: url('../assets/fonts/Assistant/Assistant-Light.ttf');
}

@font-face {
    font-family: 'Assistant';
    font-weight: 500;
    src: url('../assets/fonts/Assistant/Assistant-Regular.ttf');
}

@font-face {
    font-family: 'Assistant';
    font-weight: 600;
    src: url('../assets/fonts/Assistant/Assistant-SemiBold.ttf');
}

@font-face {
    font-family: 'Assistant';
    font-weight: 700;
    src: url('../assets/fonts/Assistant/Assistant-Bold.ttf');
}

@font-face {
    font-family: 'Assistant';
    font-weight: 800;
    src: url('../assets/fonts/Assistant/Assistant-ExtraBold.ttf');
}

html,
body {
    width: 100%;
    height: 100%;
    min-width: 1100px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-weight: 400;
    color: #444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    overflow-y: auto;
    overflow-x: auto;
}

body,
#root {
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: #f5f5f5;
}

a {
    text-decoration: none;

    &:hover {
        color: $dark-mint;
    }
}

* {
    box-sizing: border-box;
    direction: rtl;
    font-family: Assistant;
}

input[type='text'],
input[type='password'],
input[type='email'] {
    //remove box-shadow on ios
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.24);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

@media (max-width: 790px) {
    html,
    body,
    #root {
        min-width: 100%;
        width: 100%;
    }
}

.mce-tinymce {
    display: block !important;
}

.mce-panel {
    border: unset !important;
}

/*Container, container body, iframe*/
.mce-tinymce,
.mce-container-body,
#code_ifr {
    min-height: 100% !important;
}
/*Container body*/
.mce-container-body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
/*Editing area*/
.mce-container-body .mce-edit-area {
    position: absolute;
    top: 69px;
    bottom: 37px;
    left: 0;
    right: 0;
}
/*Footer*/
.mce-tinymce .mce-statusbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.mce-edit-area {
    > iframe {
        height: 100% !important;
    }
}
