@import 'style/helpers.scss';

.dialog {
    @include card5();
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 21px 19px 0 rgba(0, 0, 0, 0.1);

    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    width: 490px;
    margin-right: $side-bar-width;
    height: 80%;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.headerInvisible {
    display: none;
}

.dialogOpened {
    composes: dialog;
    -webkit-animation-name: anim-open;
    animation-name: anim-open;
}

.header {
    text-align: right;
    position: relative;

    line-height: 40px;
    padding-right: 40px;
    padding-top: 30px;
    height: unset;
    color: #303030;

    font-size: 25px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    background: #fafafa;
}

.closeButton {
    $size: 20px;
    width: $size;
    height: $size;

    position: absolute;
    right: 16px;
    top: 20px;

    user-select: none;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
}

.content {
    padding: 0;
    border-radius: 4px;
    padding: 20px;
    background: #fafafa;
    overflow-y: auto;
    height: 100%;
    flex: 1;
}

.dialogBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.24);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity;
}

@media (max-width: 790px) {
    .dialog {
        width: 90%;
    }
}
