@import 'style/helpers';

.container {
    width: 100%;
    min-height: 100px;
    h3 {
        font-weight: bold;
        text-align: right;
    }
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-top: 15px;
    justify-content: flex-start;

    > button {
        margin-left: 10px;
    }
}

.applyButton {
    @include active-button();
}

.cancelButton {
    @include not-active-button();
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;

    > div:nth-of-type(1) {
        margin-left: 10px;
        width: 45%;
        min-width: 45%;
        > div {
            min-width: 100%;
        }
    }

    > div:nth-of-type(2) {
        margin-right: 10px;
        width: 45% !important;
        min-width: 45%;
    }

    > button {
        margin-right: 10px;
    }
}

.deleteButton {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;

    * {
        fill: $tomato;
    }
}

.addButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > button {
        @include not-active-button();
    }
}

.items {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 200px;

    &[data-no-items='true'] {
        justify-content: center;
    }
}

.keyInputContainer {
    flex: 1;
    position: relative;
    padding-top: 3px;

    > div {
        max-height: 42px !important;
        height: 42px !important;
        min-height: 42px !important;

        :global {
            .select-input__control {
                min-height: 42px !important;
            }
        }
    }
}
