@import 'style/helpers.scss';

.referenceCellQuickEdit {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    > input {
        border: 2px solid $dark-mint;
        background: #fefefe;
        outline: none;
        padding-left: 5px;
        padding-right: 5px;
        width: 100%;
        height: 32px;
        font-size: 16px;
        direction: ltr;
        min-width: 50px;
        text-align: right;
    }
}

.applyButton {
    @include active-button();
    margin-left: 10px;
}

.cancelButton {
    @include not-active-button();
    margin-left: 5px;
}
