@import 'style/helpers.scss';

.contextMenuItem {
    height: 35px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 9px;
    border-bottom: solid 1px #eeeeee;
    font-size: 16px;
    min-width: 200px;
    font-weight: 500;

    &:hover {
        background-color: $dark-mint;
        color: white;
        font-weight: bold;
    }
}
