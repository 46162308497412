@import 'style/helpers.scss';

.buttonCell {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.button {
    @include not-active-button();
}
