@import 'style/helpers.scss';

.talk-bubble {
    margin: 18px;
    display: inline-block;
    position: relative;
    min-width: 200px;
    height: auto;
    background-color: white;
}

.border {
    border: 2px solid $dark-mint;
}

.round {
    border-radius: 8px;
}

.bubble {
    composes: talk-bubble;
    composes: border;
    composes: round;

    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 0;

    & {
        &:before {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: -2px;
            right: auto;
            top: auto;
            bottom: -10px;
            border: 8px solid;
            border-color: transparent transparent transparent $dark-mint;
        }
    }

    &:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 0px;
        right: auto;
        top: auto;
        bottom: -5px;
        border: 5px solid;
        border-color: transparent transparent transparent white;
    }
}

.markerActive {
    width: 0;
    height: 0;
    overflow: visible;
    position: relative;
    margin-top: 12px;
    z-index: 100;
}

.marker {
    width: 0;
    height: 0;
    overflow: visible;
    position: relative;

    img {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 35px;
        width: 35px;
        margin-left: -(35px / 2);
    }
}

.content {
    padding: 10px;
    min-height: 50px;
    text-align: left;
    position: relative;

    > div:nth-of-type(1) {
        font-weight: bold;
        font-size: 16px;
    }

    > div:nth-of-type(2) {
        clear: both;
        display: block;
        padding-top: 5px;
        font-size: 14px;
    }

    > i {
        position: absolute;
        top: 4px;
        right: 8px;
        font-size: 20px;
        cursor: pointer;
        user-select: none;
    }
}
