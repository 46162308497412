@import './variables.scss';

@mixin fill() {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@mixin scroll() {
    overflow: auto;
    overflow-scrolling: touch;

    & > .inner {
        position: relative;
        transform: translateZ(0);
        overflow: hidden;
    }
}

@mixin card1() {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@mixin card2() {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@mixin card3() {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

@mixin card4() {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@mixin card5() {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

@keyframes pulseAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
}

@mixin animated-background($backgroundSize: 800px 100px, $duration: 2s) {
    animation-duration: $duration;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: $loading-color;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: $backgroundSize;
    pointer-events: none;
}

@mixin animated-background-light($backgroundSize: 800px 100px, $duration: 2s) {
    animation-duration: $duration;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #fcfcfc;
    background: linear-gradient(to right, #fdfdfd 8%, #eeeeee 18%, #fdfdfd 33%);
    background-size: $backgroundSize;
    pointer-events: none;
}

@mixin animated-background-pulse($backgroundSize: 800px 100px, $duration: 2s) {
    animation-duration: $duration;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: pulseAnimation;
    animation-timing-function: linear;
    background: $loading-color;
    pointer-events: none;
}

@mixin text-line-stub-pulse(
    $backgroundColor,
    $height,
    $leftPadding,
    $rightPadding
) {
    background: $backgroundColor;
    height: $height;
    background: none;
    z-index: 1;
    position: relative;
    @include animated-background-pulse(100% $height, 2s);
    pointer-events: none;

    &:before {
        content: '';
        display: block;
        background: $backgroundColor;
        width: $rightPadding;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }

    &:after {
        content: '';
        display: block;
        background: $backgroundColor;
        width: $leftPadding;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
    }
}

@mixin text-line-stub($backgroundColor, $height, $leftPadding, $rightPadding) {
    background: $backgroundColor;
    height: $height;
    background: none;
    z-index: 1;
    position: relative;
    //@include animated-background-pulse(100% $height, 2s);
    pointer-events: none;

    &:before {
        content: '';
        display: block;
        background: $backgroundColor;
        width: $rightPadding;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }

    &:after {
        content: '';
        display: block;
        background: $backgroundColor;
        width: $leftPadding;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
    }
}

@mixin active-button() {
    display: inline-block;

    height: 30px;
    line-height: 30px;

    border: none;
    outline: none;
    border-radius: 4px;
    background-color: $dark-mint;
    box-shadow: 0 6px 8px 0 #b6f3c4;

    padding-left: 20px;
    padding-right: 20px;

    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;

    white-space: nowrap;

    cursor: pointer;
    user-select: none;
    text-align: center;

    &:hover {
        background: darken($dark-mint, 10%);
        box-shadow: 0 3px 4px 0 #b6f3c4;
    }
}

@mixin not-active-button() {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    line-height: 30px;

    border-radius: 4px;
    background-color: #fefefe;

    border: none;
    outline: none;
    border: solid 1px #eeeeee;

    padding-left: 20px;
    padding-right: 20px;

    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #798a7b;

    white-space: nowrap;

    cursor: pointer;
    user-select: none;

    &:hover {
        background: darken(#fefefe, 10%);
        color: white;
    }
}

@mixin label() {
    height: 15px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: right;
    color: rgba(black, 0.5);
}

@mixin group() {
    border-radius: 4px;
    background-color: #fafafa;
    border: solid 1px #eeeeee;
}

@mixin input() {
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px $input-border-color;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
    height: 28px;
    min-height: 28px;
    font-size: 16px;
    font-weight: 500;

    &:not(disabled):focus {
        border: 1px solid transparent;
        box-shadow: inset 0px 0px 0px 2px $dark-mint;
    }

    &:disabled {
        background-color: $disabled-input-background;
    }

    &[data-error='true'],
    &[data-error='true']:focus,
    &[data-error='true']:disabled {
        border: 1px solid transparent;
        box-shadow: inset 0px 0px 0px 2px $tomato;
    }
}
