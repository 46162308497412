@import 'style/helpers.scss';

.numberCell {
    width: 100%;
    height: 35px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
        min-width: 50px;
        text-align: right;
    }
}

.numberCellEdit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    padding: 5px;

    > input {
        @include card2();
        border: 2px solid $dark-mint;
        background: #fefefe;
        outline: none;
        padding-left: 5px;
        padding-right: 5px;
        width: 100%;
        height: 32px;
        font-size: 16px;
        direction: ltr;
        min-width: 50px;
        text-align: right;
    }
}
