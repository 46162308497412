@import 'style/helpers.scss';

.quickEditorWrapper {
    position: fixed;

    z-index: 1000;
    opacity: 0.9;
    transform: translate(50%, 0);
    padding-top: 10px;

    &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.content {
    display: inline-flex;
}

.bubble {
    @include card3();
    display: inline-flex;
    background: white;
    border-radius: 4px;
    padding: 15px;

    position: relative;
    // overflow: hidden;
}

.processingStub {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba($color: white, $alpha: 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
