@import 'style/helpers.scss';

.loadingStub {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
        > div {
            width: 20px;
            height: 20px;
            margin: 4px;
        }
    }
}
