@import 'style/helpers';

.editDialog {
    z-index: 1000;
    min-width: 450px;

    h3 {
        font-weight: bold;
        text-align: right;
    }
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;

    > div {
        width: 100%;
    }
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-top: 15px;
    justify-content: flex-start;

    > button {
        margin-left: 10px;
    }
}

.applyButton {
    @include active-button();
}

.cancelButton {
    @include not-active-button();
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    > *:first-child {
        margin-left: 10px;
    }

    > button {
        margin-right: 10px;
    }
}

.deleteButton {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;

    * {
        fill: $tomato;
    }
}

.addButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > button {
        @include not-active-button();
    }
}

.items {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 200px;

    &[data-no-items='true'] {
        justify-content: center;
    }
}

.keyInputContainer {
    flex: 1;
    position: relative;

    > div {
        max-height: 52px !important;
        height: 52px !important;
        min-height: 52px !important;
    }
}
