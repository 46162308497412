@import 'style/helpers.scss';

$padding-right: 12px;
$height: 42px;

.label {
    @include label();
    position: absolute;
    z-index: 100;
    right: $padding-right;
    top: 4px;
    text-align: left;
    font-size: 12px;
}

.openButton {
    position: absolute;
    left: 10px;
    z-index: 3000;
}

.referenceInputContainer {
    display: inline-block;
    position: relative;
    height: $height;
    min-height: $height;
    max-height: $height;
    min-width: 300px;

    > div {
        height: 100%;
    }

    :global {
        .reference-input__control {
            //width: 113px;
            border-radius: 4px !important;
            background-color: white;

            position: absolute !important;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid $input-border-color !important;
        }

        .reference-input-error__control {
            //width: 113px;
            border-radius: 4px !important;
            background-color: white;

            position: absolute !important;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: solid 2px $tomato !important;
        }

        .reference-input__value-container--is-multi {
            padding-top: 16px !important;
        }

        .reference-input__control.reference-input__control--is-focused {
            //width: 113px;
            outline: none !important;

            position: absolute !important;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid transparent !important;
            box-shadow: inset 0 0 0 2px $dark-mint !important;
        }

        .reference-input-error__control.reference-input-error__control--is-focused {
            //width: 113px;
            border-radius: 4px !important;
            outline: none !important;
            border: 1px solid transparent !important;
            box-shadow: inset 0 0 0 2px $tomato !important;

            position: absolute !important;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .reference-input__control-separator {
            display: none;
        }

        .reference-input__control--is-disabled {
            background-color: hsl(0, 0%, 95%);
        }

        .reference-input__value-container {
            font-size: 16px;
            outline: none;
        }

        .reference-input__indicator-separator {
            margin-top: 6px !important;
        }

        .reference-input__control.reference-input__control--is-disabled {
            background-color: $disabled-input-background;
            border: solid 1px #e6e6e6;
            color: $charcoal-grey;
        }

        .reference-input__single-value,
        .reference-input__placeholder,
        .reference-input-error__placeholder {
            padding-top: 15px;
            font-weight: 500;
        }

        .reference-input__single-value.reference-input__single-value--is-disabled {
            color: $charcoal-grey;
        }

        .react-select__option {
            font-size: 16px;
        }

        .reference-input__indicator.reference-input__dropdown-indicator {
            font-size: 13px;
            color: $dark-mint;
        }

        .reference-input__indicators {
            height: 100% !important;
        }

        .reference-input__value-container {
            padding: 0;
            padding-right: 8px;
            height: 100% !important;
        }

        .reference-input__menu,
        .reference-input-error__menu {
            top: 32px;
            z-index: 1500;
        }
    }
}

.createButton {
    @include not-active-button();
    position: absolute;
    left: 50px;
    top: 10px;
    z-index: 3000;
    height: unset;
    line-height: 22px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 100px;
    width: unset;
}
