@import 'style/helpers.scss';

.list {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    padding: 5px;
    background: #f4f4f4;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;

    > div {
        width: 100%;
    }
}

.items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > * {
        margin-bottom: 5px;
    }
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;
    border-radius: 4px;
    padding: 5px;
    padding-right: 15px;

    border: 1px solid #eee;
}

.itemButton {
    @include not-active-button();
}
