@import 'style/helpers.scss';

.container {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fefefe;
    border: solid 1px #eeeeee;
    max-width: 100%;
    width: 100%;
}

.content {
    min-height: 185px;
    padding: 20px 30px 25px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    > div:first-child {
        margin-left: 10px;
    }
}

.processing {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 250px;
    height: 25px;
    background: rgba(white, 0.5);
    z-index: 100;
    pointer-events: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: rgba($dark-mint, 0.7);

    color: white;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;

    > div {
    }
}

.processText {
    color: $charcoal-grey;
    font-size: 16px;
    margin-bottom: 20px;
}

.emptyStub {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $charcoal-grey;

    font-size: 18px;
}
