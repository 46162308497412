@import 'style/helpers.scss';

.contextmenutrigger {
}

.row {
    $border: solid 1px #eeeeee;

    height: 35px;
    max-height: 35px;
    border-radius: 4px;
    background-color: #fefefe;
    border: solid 1px #eeeeee;
    margin-bottom: 4px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        $active-border: solid 1px $algae-green !important;
        $active-background: darken(#fefefe, 3%) !important;

        > td {
            border-top: $active-border;
            border-bottom: $active-border;
            background-color: $active-background;
        }

        > td:first-child {
            background-color: $active-background;
            border-right: $active-border;
            border-right-style: solid;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        > td:last-child {
            background-color: $active-background;
            border-left: $active-border;
            border-left-style: solid;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }
    }

    > td {
        border-top: $border;
        border-bottom: $border;
    }

    > td:first-child {
        background-color: #fefefe;
        border-right: $border;
        border-right-style: solid;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    > td:last-child {
        background-color: #fefefe;
        border-left: $border;
        border-left-style: solid;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
    }
}
