@import 'style/helpers.scss';

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 524px) {
    .footer {
        flex-direction: column;
    }
}

.text {
    font-size: 14px;
    font-weight: 700;
    font-style: oblique;
    font-stretch: normal;
    line-height: 2.17;
    letter-spacing: normal;
    color: rgba(black, 0.5);
}
