@import 'style/helpers.scss';

.tabsContainer {
    flex: 1;

    border-radius: 4px;
    background-color: #f5f5f5;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.content {
    background: #fafafa;
    min-height: 300px;

    display: flex;
    flex-direction: column;

    flex: 1;
}
