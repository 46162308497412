@import 'style/helpers.scss';

.booleanCell {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
}
