@import 'style/helpers.scss';

.cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding-right: 10px;
    cursor: pointer;
    user-select: none;

    > i {
        color: $dark-mint;
        font-size: 20px;
        padding-right: 10px;
        cursor: pointer;
        user-select: none;
        pointer-events: none;
    }
}
