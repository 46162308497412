@import 'style/helpers.scss';

.header {
    position: relative;
    z-index: 1000;
}

.title {
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    color: rgba($color: #000000, $alpha: 0.3);
    height: 40px;
    line-height: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:first-child {
        padding-right: 8px;
    }
}

.filterHeader {
    height: 43px;
    border-radius: 4px;
    background-color: #fefefe;
}
