@import 'style/helpers.scss';

$side-bar-width: 210px;

.form {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow-x: auto;
    overflow-y: auto;
    //padding-right: $side-bar-width;
}

.content {
    flex: 1;
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    padding: 20px;
}

@media (max-width: 524px) {
    .content {
        padding: 10px;
    }
}
