@import 'style/helpers.scss';

.landingPreview {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;

    background: #d8d8d8;

    > iframe {
        background: white;
    }
}
