@import 'style/helpers.scss';

.notificationContainer {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    width: 280px;
    max-width: 280px;
}

.notification {
    @include card4();
    display: flex;
    overflow: hidden;
    height: auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-height: 80px;
    margin-top: 10px;
    //border-radius: 4px;
    padding: 10px;
    padding-right: 15px;
    z-index: 1000;
    background: white;
    direction: ltr;
    text-align: right;

    * {
        direction: ltr;
    }

    &:before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 10px;
        background: $dark-mint;
    }

    &[data-type='error'] {
        &:before {
            background: $tomato;
        }
    }

    &[data-type='warning'] {
        &:before {
            background: $macaroni-and-cheese;
        }
    }
}

.content {
    font-size: 16px;
    font-weight: bold;
}

.closeButton {
    position: absolute;
    top: 5px;
    left: 10px;

    background: none;
    padding: 2px;
    height: unset;
    width: unset;
    border: none;
    outline: none;
    cursor: pointer;
    user-select: none;

    > i {
        font-size: 25px;
        cursor: pointer;
        user-select: none;
    }

    &:hover {
        > i {
            color: $dark-mint;
        }
    }
}
