@import 'style/helpers.scss';

.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 300px;
    height: 42px;
    border-radius: 4px;
    background-color: white;
    border: solid 1px #d6d6d6;

    width: 100%;
    padding-left: 8px;
    padding-right: 10px;

    > div {
        margin-top: 5px;
    }
}

.label {
    @include label();
    flex: 1;
    text-align: right;
    padding-left: 15px;

    &[data-error='true'] {
        color: red;
    }
}
